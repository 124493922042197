<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-dialog
        v-model="showform"
        max-width="1400px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span v-if="!annulus">
              {{ item.id > 0 ? "String " + item.item : "New String" }}
            </span>
            <span v-else>
              {{ item.annulus }}
            </span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-row v-if="!annulus">
              <v-col cols="12" sm="5" md="5">
                <v-select
                  :items="tubulars"
                  v-model="editedItem.tubular_id"
                  item-value="id"
                  item-text="item"
                  label="Tubular"
                  :rules="[(v) => !!v || 'Tubular required']"
                  outlined
                  :disabled="!modify"
                  @input="change++"
                  :readonly="sizes_list.length > 0"
                  @change="tubular_change"
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="1" md="1">
                <v-select
                  :items="tubulars_positions"
                  v-model="editedItem.csg_order"
                  label="Position"
                  item-value="id"
                  item-text="id"
                  outlined
                  readonly
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2" md="2" v-if="editedItem.id > 0">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.depthfrom"
                  :label="'Depth From (' + depth_unit + ')'"
                  @input="change++"
                  @focus="$event.target.select()"
                  readonly
                  hide-spin-buttons
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" v-if="editedItem.id > 0">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.depthto"
                  :label="'Depth To (' + depth_unit + ')'"
                  readonly
                  hide-spin-buttons
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" v-if="editedItem.id > 0">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.depth"
                  :label="'Depth (' + depth_unit + ')'"
                  readonly
                  hide-spin-buttons
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" v-if="editedItem.tubular_id != 1">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.topcement"
                  @input="change++"
                  :rules="[
                    (v) => v != null || 'Top of Cement required ',
                    (v) =>
                      v === '' ||
                      v === null ||
                      isNaN(v) ||
                      v >= editedItem.depthfrom ||
                      editedItem.depthfrom == null ||
                      'Error Min Position : ' +
                        numberWithSpace(editedItem.depthfrom),
                    (v) =>
                      v === '' ||
                      v === null ||
                      isNaN(v) ||
                      v <= well.td ||
                      'Error Max Position : ' + numberWithSpace(well.td),
                    (v) =>
                      v === '' ||
                      v === null ||
                      isNaN(v) ||
                      v <= editedItem.depthto ||
                      editedItem.depthto == null ||
                      'Error Max Position : ' +
                        numberWithSpace(editedItem.depthto),
                    (v) =>
                      v == '' ||
                      v == null ||
                      isNaN(v) ||
                      v >= parseFloat(packer_next) + 15 ||
                      packer_next == null ||
                      'Error Max Position : ' +
                        numberWithSpace(parseFloat(packer_next) + 15),
                  ]"
                  :label="'Top of Cement (' + depth_unit + ')'"
                  :disabled="
                    editedItem.tubular_id == null || editedItem.tubular_id == 1
                  "
                  :readonly="!modify"
                  @focus="$event.target.select()"
                  clearable
                  hide-spin-buttons
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="2"
                md="2"
                v-if="editedItem.tubular_id == 1 && editedItem.id > 0"
              >
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.sssv"
                  @input="change++"
                  :rules="[
                    (v) =>
                      v === '' ||
                      v === null ||
                      isNaN(v) ||
                      v >= editedItem.depthfrom ||
                      'Error Min Position : ' +
                        numberWithSpace(editedItem.depthfrom),
                    (v) =>
                      v === '' ||
                      v === null ||
                      isNaN(v) ||
                      v <= editedItem.depthto ||
                      'Error Max Position : ' +
                        numberWithSpace(editedItem.depthto),
                  ]"
                  :label="'SSSV (' + depth_unit + ')'"
                  :disabled="editedItem.depthto == null"
                  :readonly="!modify"
                  @focus="$event.target.select()"
                  clearable
                  hide-spin-buttons
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" v-if="editedItem.id > 0">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.tvd"
                  :rules="[
                    (v) =>
                      isNaN(v) ||
                      v <= editedItem.depthto ||
                      'Error Max Value : ' +
                        numberWithSpace(editedItem.depthto),
                  ]"
                  @input="change++"
                  :label="'TVD (' + depth_unit + ')'"
                  :disabled="editedItem.depthto == null"
                  :readonly="!modify"
                  @focus="$event.target.select()"
                  clearable
                  hide-spin-buttons
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  autocomplete="off"
                  outlined
                  dense
                  v-model.number="editedItem.details"
                  :label="'Comments'"
                  :readonly="!modify"
                  @input="change++"
                  @focus="$event.target.select()"
                  hide-spin-buttons
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="annulus">
              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.volume"
                  :label="'Volume (' + $store.state.options[0].volume + ')'"
                  @input="change++"
                  hide-spin-buttons
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" v-if="editedItem.tubular_id != 1">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.maasp"
                  :label="
                    'Initial MAASP (' + $store.state.options[0].pressure + ')'
                  "
                  @input="change++"
                  readonly
                  disabled
                  hide-spin-buttons
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" v-if="editedItem.tubular_id != 1">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.maasp_calc"
                  :label="
                    'Calculated MAASP (' +
                    $store.state.options[0].pressure +
                    ')'
                  "
                  @input="change++"
                  readonly
                  disabled
                  hide-spin-buttons
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" v-if="editedItem.tubular_id != 1">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.maasp_estim"
                  :label="
                    'Estimated MAASP (' + $store.state.options[0].pressure + ')'
                  "
                  @input="change++"
                  readonly
                  disabled
                  hide-spin-buttons
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" v-if="editedItem.tubular_id != 1">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.maop"
                  :label="'MAOP (' + $store.state.options[0].pressure + ')'"
                  @input="change++"
                  readonly
                  disabled
                  hide-spin-buttons
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="annulus">
              <v-col cols="12" sm="2" md="2">
                <v-select
                  :items="fluids"
                  v-model="editedItem.fluid_id"
                  label="Fluid Type"
                  item-value="id"
                  item-text="label"
                  outlined
                  @input="change++"
                  clearable
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2" md="2" v-if="editedItem.tubular_id != 1">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.fluid_density"
                  :rules="[
                    (v) =>
                      !!v ||
                      !editedItem.fluid_id ||
                      v > 0 ||
                      'Fluid density required',
                  ]"
                  :label="'Fluid density (ppg)'"
                  @input="change++"
                  hide-spin-buttons
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="2"
                md="2"
                v-if="editedItem.tubular_id != 1 && editedItem.tubular_id != 6"
              >
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.wh_press_rate"
                  :label="
                    'Wellhead pressure rating (' +
                    $store.state.options[0].pressure +
                    ')'
                  "
                  @input="change++"
                  hide-spin-buttons
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="2" md="2" v-if="editedItem.tubular_id != 1">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.lot"
                  :label="'LOT (ppg)'"
                  @input="change++"
                  hide-spin-buttons
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" v-if="editedItem.tubular_id != 1">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.alert_min"
                  :label="
                    'Min Alert (' + $store.state.options[0].pressure + ')'
                  "
                  :rules="[
                    (v) => !v || v > 0 || 'value error',
                    (v) =>
                      !v || v <= actual_maasp || 'Max value :' + actual_maasp,
                  ]"
                  @input="change++"
                  :readonly="!modify"
                  hide-spin-buttons
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" v-if="editedItem.tubular_id != 1">
                <v-text-field
                  autocomplete="off"
                  type="number"
                  outlined
                  dense
                  v-model.number="editedItem.alert_max"
                  :rules="[
                    (v) => !v || v > 0 || 'value error',
                    (v) =>
                      !v || v <= actual_maasp || 'Max value :' + actual_maasp,
                  ]"
                  :label="
                    'Max Alert (' + $store.state.options[0].pressure + ')'
                  "
                  @input="change++"
                  :readonly="!modify"
                  hide-spin-buttons
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <!-- to put autorisation for this -->
            <v-btn
              color="blue darken-1"
              @click.stop="tieback"
              v-if="
                editedItem.depthfrom > 0 &&
                editedItem.id > 0 &&
                editedItem.tieback &&
                editedItem.statut_id == '2' &&
                !annulus
              "
            >
              Tie-back
            </v-btn>
            <v-spacer> </v-spacer>
            <v-btn
              color="blue darken-1"
              :disabled="change == 0 || size_edit"
              @click.stop="save"
            >
              Save
            </v-btn>
            <v-btn
              color="blue darken-1"
              @click.stop="close"
              :disabled="size_edit"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card v-if="editedItem.id > 0 && !annulus">
          <v-card-title>
            <span> {{ item.item + " Details" }} </span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-tabs v-model="tab0">
              <v-tabs-slider></v-tabs-slider>
              <v-tab key="1" href="#1"> Sizes </v-tab>
              <v-tab key="2" href="#2" v-if="editedItem.tubular_id != 1">
                Packers
              </v-tab>
            </v-tabs>
          </v-card-text>
          <v-card-actions>
            <v-container fluid>
              <v-tabs-items v-model="tab0">
                <v-tab-item :value="'1'">
                  <v-form ref="form2" lazy-validation>
                    <v-container fluid>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="2"
                          md="2"
                          v-if="
                            editedItem.tubular_id == 6 &&
                            sizes_list.length > 0 &&
                            !size_edit
                          "
                        >
                          <v-select
                            :items="[
                              { id: -1, label: 'Before' },
                              { id: 1, label: 'After' },
                            ]"
                            v-model="size.position"
                            :item-value="'id'"
                            :item-text="'label'"
                            label="Position"
                            :rules="[(v) => !!v || 'Position required']"
                            outlined
                            @change="api_change"
                            dense
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                          <v-autocomplete
                            :items="apicasing_list"
                            v-model="size.od_in"
                            label="Size (in)"
                            :item-value="'od_in'"
                            :item-text="'label'"
                            :rules="[(v) => !!v || 'Size required']"
                            @change="api_change"
                            outlined
                            dense
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          :sm="
                            editedItem.tubular_id == 6 && sizes_list.length > 0
                              ? 1
                              : 2
                          "
                          :md="
                            editedItem.tubular_id == 6 && sizes_list.length > 0
                              ? 1
                              : 2
                          "
                        >
                          <v-autocomplete
                            :items="weights"
                            v-model="size.weight_lbm"
                            label="Weight (lbm/ft)"
                            :item-value="'weight_lbm'"
                            :item-text="'weight_lbm'"
                            :rules="[(v) => !!v || 'Weight required']"
                            outlined
                            dense
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="1" md="1">
                          <v-autocomplete
                            :items="grades"
                            v-model="size.grade"
                            label="Grade"
                            :item-value="'grade'"
                            :item-text="'grade'"
                            :rules="[(v) => !!v || 'Grade required']"
                            outlined
                            dense
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                          <v-text-field
                            autocomplete="off"
                            type="number"
                            outlined
                            dense
                            v-model.number="size.depthfrom"
                            :label="'Depth From (' + depth_unit + ')'"
                            :rules="[
                              (v) => v >= 0 || 'Depth From required',
                              (v) =>
                                !v ||
                                v <= max_depth ||
                                'Error ! : Max length : ' + max_depth,
                            ]"
                            :disabled="size.depthfrom_lock"
                            @input="change++"
                            @focus="$event.target.select()"
                            :key="kf"
                            hide-spin-buttons
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                          <v-text-field
                            autocomplete="off"
                            type="number"
                            outlined
                            dense
                            v-model.number="size.depthto"
                            :label="'Depth To (' + depth_unit + ')'"
                            :rules="[
                              (v) => v > 0 || 'Depth To required',

                              (v) =>
                                !v ||
                                v <= max_depth ||
                                'Error ! : Max length : ' + max_depth,
                            ]"
                            :disabled="size.depthto_lock"
                            :key="kf"
                            hide-spin-buttons
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="1" md="1" class="text-right">
                          <v-btn
                            color="blue darken-1"
                            :disabled="
                              size.od_in == null ||
                              size.weight_lbm == null ||
                              size.grade == null
                            "
                            @click.stop="save_size"
                          >
                            Save
                          </v-btn>
                        </v-col>

                        <v-col cols="12" sm="1" md="1" class="text-left">
                          <v-btn
                            color="blue darken-1"
                            :disabled="
                              (size.depthto == null || size.depthto == 0) &&
                              size.od_in == null &&
                              size.weight_lbm == null &&
                              size.grade == null
                            "
                            @click.stop="cancel"
                          >
                            Cancel
                          </v-btn>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <listitems
                            :list="sizes_list"
                            :headers="sizes_headers"
                            :Get_suf="'Wellstubularsize'"
                            :showstd="false"
                            :showedit="false"
                            :add="false"
                            :del="false"
                            :master="true"
                            :exp_excel="false"
                            :key="size_key"
                            :ipg="-1"
                            :hdf="true"
                            @rowselect="SizeSelect"
                            @col_btn3_click="size_update"
                            @col_btn4_click="size_delete"
                            :laoding="data_loading"
                            :selitem="actualsize"
                          >
                          </listitems>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-tab-item>
                <v-tab-item :value="'2'">
                  <v-form ref="form3" lazy-validation>
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12" sm="2" md="2">
                          <v-text-field
                            autocomplete="off"
                            type="number"
                            outlined
                            dense
                            v-model.number="packer.depth"
                            :label="'Packer position (' + depth_unit + ')'"
                            :rules="[
                              (v) => v > 0 || 'Packer position required',
                              (v) =>
                                isNaN(v) ||
                                v >= editedItem.depthfrom ||
                                'Error Min Position:' +
                                  numberWithSpace(editedItem.depthfrom),
                              (v) =>
                                isNaN(v) ||
                                v <= editedItem.depthto ||
                                'Error Max Position: ' +
                                  numberWithSpace(editedItem.depthto),
                              (v) =>
                                isNaN(v) ||
                                v <= toc_prec ||
                                toc_prec == null ||
                                'Error Max Position:' +
                                  numberWithSpace(toc_prec),
                            ]"
                            hide-spin-buttons
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" md="2">
                          <v-text-field
                            autocomplete="off"
                            type="number"
                            outlined
                            dense
                            v-model.number="packer.pressure_rating"
                            :label="
                              'Pressure Rating (' +
                              $store.state.options[0].pressure +
                              ')'
                            "
                            :rules="[
                              (v) => v > 0 || 'Pressure Rating required',
                            ]"
                            hide-spin-buttons
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="1" md="1">
                          <v-btn
                            color="blue darken-1"
                            :disabled="
                              packer.depth == null || packer.depth == 0
                            "
                            @click.stop="save_packer"
                          >
                            Save
                          </v-btn>
                        </v-col>

                        <v-col cols="12" sm="1" md="1">
                          <v-btn
                            color="blue darken-1"
                            :disabled="
                              packer.depth == null || packer.depth == 0
                            "
                            @click.stop="cancel"
                            class="mx-2"
                          >
                            Cancel
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <listitems
                            :list="packers_list"
                            :headers="packers_headers"
                            :Get_suf="'Wellstubularpacker'"
                            :showstd="false"
                            :showedit="false"
                            :add="false"
                            :del="false"
                            :master="true"
                            :exp_excel="false"
                            :key="packer_key"
                            :ipg="-1"
                            :hdf="true"
                            @rowselect="PackerSelect"
                            @col_btn3_click="packer_update"
                            @col_btn4_click="packer_delete"
                            :laoding="data_loading"
                            :selitem="actualpacker"
                          >
                          </listitems>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form> </v-tab-item
              ></v-tabs-items>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>
<script>
import CREATE_TUBULAR from "../graphql/Well/CREATE_TUBULAR.gql";
import UPDATE_TUBULAR from "../graphql/Well/UPDATE_TUBULAR.gql";
import CREATE_TUBULAR_SIZE from "../graphql/Well/CREATE_TUBULAR_SIZE.gql";
import UPDATE_TUBULAR_SIZE from "../graphql/Well/UPDATE_TUBULAR_SIZE.gql";
import DELETE_TUBULAR_SIZE from "../graphql/Well/DELETE_TUBULAR_SIZE.gql";

import CREATE_TUBULAR_PACKER from "../graphql/Well/CREATE_TUBULAR_PACKER.gql";
import UPDATE_TUBULAR_PACKER from "../graphql/Well/UPDATE_TUBULAR_PACKER.gql";
import DELETE_TUBULAR_PACKER from "../graphql/Well/DELETE_TUBULAR_PACKER.gql";

export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    list: Array,
    apicasing: Array,
    showform: Boolean,
    modify: Boolean,
    item: Object,
    max_id_in: Number,
    depth_unit: String,
    tubulars: Array,
    fluids: Array,
    well: Object,
    annulus: { type: Boolean, default: false },
  },
  data() {
    return {
      document: {},
      data_loading: false,
      isDocClose: true,
      ff: 900,
      kf: 450,
      size: {},
      packer: {},
      actualsize: {},
      actualpacker: {},
      size_key: 1000,
      packer_key: 1000,
      btn_click: false,
      return_list: [],
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      editedItem: {
        depthfrom: 0,
      },
      change: 0,
      direct_up: false,
      toc_prec: null,
      depthto_prec: null,
      packer_next: null,
      size_edit: false,
      tab0: "1",
      packers_headers: [
        {
          text: "Order",
          value: "no",
          selected: true,
          width: "10",
          sortable: false,
        },

        {
          text: "Depth",
          value: "depth",
          selected: true,
          slot: "cur",
          align: "end",
          sortable: false,
        },
        {
          text: "Pressure Rating",
          value: "pressure_rating",
          selected: true,
          slot: "cur",
          align: "end",
          sortable: false,
        },
        {
          text: "Update",
          slot: "col_btn3",
          selected: true,
          icon: "mdi-pencil",
          width: "10",
          sortable: false,
        },
        {
          text: "Delete",
          slot: "col_btn4",
          icon: "mdi-delete",
          selected: true,
          width: "10",
          sortable: false,
        },
      ],
      sizes_headers: [
        {
          text: "Position",
          value: "no",
          selected: true,
          width: "10",
          sortable: false,
        },

        {
          text: "Size",
          value: "label",
          selected: true,
          width: "150",
          sortable: false,
        },
        {
          text: "Weight",
          value: "weight_lbm",
          selected: true,
          width: "180",
          sortable: false,
        },
        {
          text: "Grade",
          value: "grade",
          selected: true,
          width: "30",
          sortable: false,
        },

        {
          text: "Depth From",
          value: "depthfrom",
          selected: true,
          slot: "cur",
          align: "end",
          width: "100",
          sortable: false,
        },
        {
          text: "Depth To",
          value: "depthto",
          selected: true,
          slot: "cur",
          align: "end",
          width: "100",
          sortable: false,
        },
        {
          text: "Length",
          value: "depth",
          selected: true,
          slot: "cur",
          align: "end",
          width: "100",
          sortable: false,
        },

        {
          text: "Collapse Resist.",
          value: "collapse",
          slot: "cur33",
          align: "right",
          selected: false,
          width: "120",
          sortable: false,
        },
        {
          text: "IY",
          value: "iy",
          slot: "cur33",
          align: "right",
          selected: false,
          width: "120",
          sortable: false,
        },
        {
          text: "PBY",
          value: "pby",
          slot: "cur33",
          align: "right",
          selected: false,
          width: "120",
          sortable: false,
        },
        {
          text: "PBIY",
          value: "pbiy",
          slot: "cur33",
          align: "right",
          selected: false,
          width: "100",
          sortable: false,
        },
        {
          text: "ID (in)",
          value: "id_in",
          slot: "cur33",
          align: "right",
          selected: false,
          width: "100",
          sortable: false,
        },
        {
          text: "ID (mm)",
          value: "id_mm",
          slot: "cur33",
          align: "right",
          selected: false,
          width: "100",
          sortable: false,
        },
        {
          text: "Capacity (mm)",
          value: "capacity",
          slot: "cur33",
          align: "right",
          selected: false,
          width: "100",
          sortable: false,
        },
        {
          text: "open (mm)",
          value: "open",
          slot: "cur33",
          align: "right",
          selected: false,
          width: "100",
          sortable: false,
        },
        {
          text: "Plugged (mm)",
          value: "plugged",
          slot: "cur33",
          align: "right",
          selected: false,
          width: "100",
          sortable: false,
        },
        {
          text: "Update",
          slot: "col_btn3",
          selected: true,
          icon: "mdi-pencil",
          width: "10",
          sortable: false,
        },
        {
          text: "Delete",
          slot: "col_btn4",
          icon: "mdi-delete",
          selected: true,
          width: "10",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    item: {
      handler() {
        this.editedItem = Object.assign({}, this.item);
        this.size_key++;
        this.packer_key++;
        if (this.$refs.form2) this.$refs.form2.resetValidation();
        if (this.$refs.form3) this.$refs.form3.resetValidation();
        this.$nextTick(() => {
          this.size = {};
          this.packer = {};
          this.size.position = -1;
        });
        this.data_loading = false;
      },
      deep: true,
    },
  },
  created() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
      } else {
        this.editedItem.csg_order = this.list.length + 1;
      }
      this.size.position = -1;
    }

    let i = this.list.findIndex(
      (elm) => elm.csg_order == this.editedItem.csg_order - 1
    );
    if (i >= 0) {
      this.toc_prec = this.list[i].topcement;
      this.depthto_prec = this.list[i].depthto;
    }
    i = this.list.findIndex(
      (elm) => elm.csg_order == this.editedItem.csg_order + 1
    );
    if (i >= 0) {
      this.packer_next = this.list[i].packer;
    }
    this.packers_headers[1].text =
      this.packers_headers[1].text + " (" + this.depth_unit + ")";
    this.packers_headers[2].text =
      this.packers_headers[2].text +
      " (" +
      this.$store.state.options[0].pressure +
      ")";
    for (let index = 4; index < 7; index++) {
      this.sizes_headers[index].text =
        this.sizes_headers[index].text + " (" + this.depth_unit + ")";
    }
  },
  computed: {
    actual_maasp() {
      return this.editedItem.maasp_calc
        ? this.editedItem.maasp_calc
        : this.editedItem.maasp;
    },
    max_depth() {
      return this.well.td;
    },
    apicasing_list() {
      let l = [];
      if (this.item.tubular_id == 6) {
        l = this.apicasing.filter(
          (elm) =>
            elm.csg_type == this.item.type &&
            (this.item.max_id_in ? elm.od_in < this.item.max_id_in : true)
        );
      } else l = this.apicasing.filter((elm) => elm.csg_type == this.item.type);

      return l;
    },
    weights() {
      let l = [];
      l = this.apicasing_list.filter((elm) => elm.od_in == this.size.od_in);
      return l;
    },
    grades() {
      let l = [];
      l = this.apicasing_list.filter(
        (elm) =>
          elm.od_in == this.size.od_in && elm.weight_lbm == this.size.weight_lbm
      );
      return l;
    },
    tubulars_positions() {
      let l = [];
      for (let index = 0; index < this.list.length; index++) {
        l.push({
          id: index + 1,
        });
      }
      if (this.item.id < 0) l.push({ id: l.length + 1 });
      return l;
    },
    sizes_positions() {
      let l = [];
      for (let index = 0; index < this.sizes_list.length; index++) {
        l.push(index + 1);
      }
      if (!this.size_edit) l.push(l.length + 1);
      return l;
    },
    sizes_list: function () {
      return this.editedItem.sizes_list ? this.editedItem.sizes_list : [];
    },
    packers_list: function () {
      return this.editedItem.packers_list ? this.editedItem.packers_list : [];
    },
  },
  methods: {
    api_change() {
      if (!this.size_edit) {
        this.size.depthto_lock = false;
        this.size.depthfrom_lock = false;
        if (this.sizes_list.length > 0) {
          if (this.editedItem.tubular_id != 6) {
            this.size.depthfrom =
              this.sizes_list[this.sizes_list.length - 1].depthto;
            this.size.depthto = null;
            this.size.depthfrom_lock = true;
          } else {
            if (this.size.position == -1) {
              this.size.depthfrom = null;
              this.size.depthto = this.sizes_list[0].depthfrom;
              this.size.depthto_lock = true;
            } else {
              this.size.depthfrom = this.sizes_list[0].depthto;
              this.size.depthto = null;
              this.size.depthfrom_lock = true;
            }
          }
        } else if (this.sizes_list.length == 0) {
          if (this.editedItem.tubular_id != 6) {
            this.size.depthfrom_lock = true;
            this.size.depthfrom = 0;
            this.size.depthto = null;
          } else {
            this.size.depthfrom_lock = false;
            this.size.depthto_lock = false;
            this.size.depthfrom = null;
            this.size.depthto = null;
          }
        }
        this.kf++;
      }
    },
    tubular_change() {
      if (this.editedItem.tubular_id == 1) this.editedItem.csg_order = 1;
    },
    close() {
      this.$emit("close");
    },
    SizeSelect(item) {
      this.actualsize = item;
    },
    size_update(item) {
      this.size = Object.assign({}, item);
      this.size_edit = true;
      this.sizes_headers[16].hiden = this.size_edit;
      this.sizes_headers[17].hiden = this.size_edit;
      this.size_key++;
      this.size.depthto_lock = true;
      this.size.depthfrom_lock = true;
      if (this.sizes_list.length == 1) {
        this.size.depthto_lock = false;
        if (this.editedItem.tubular_id == 6) {
          this.size.depthfrom_lock = false;
        }
      }
      if (this.sizes_list.length > 1) {
        if (item.index == 0 && this.editedItem.tubular_id == 6) {
          this.size.depthfrom_lock = false;
        }

        if (item.index == this.sizes_list.length - 1)
          this.size.depthto_lock = false;
      }
    },
    PackerSelect(item) {
      this.actualpacker = item;
    },
    packer_update(item) {
      this.packer = Object.assign({}, item);
      this.packer_edit = true;
    },
    async size_delete(item) {
      if (this.sizes_list.length > 1) {
        if (item.index != this.sizes_list.length - 1) return;
      }
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Delete size position " + (item.index + 1) + " !!-Are you sur ?",
          { color: "orange darken-3" }
        )
      ) {
        this.data_loading = true;
        let r = await this.$maj(DELETE_TUBULAR_SIZE, { id: item.id });
        if (r) {
          this.$emit("change", this.editedItem);

          this.$refs.form2.resetValidation();
          this.actualsize = {};
        } else this.data_loading = false;
      }
    },
    async packer_delete(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Delete packer position " + (item.index + 1) + " !!-Are you sur ?",
          { color: "orange darken-3" }
        )
      ) {
        this.data_loading = true;
        let r = await this.$maj(DELETE_TUBULAR_PACKER, { id: item.id });
        if (r) {
          this.$emit("change", this.editedItem);

          this.$refs.form3.resetValidation();

          if (item.index > 0)
            this.actualpacker = this.packers_list[item.index - 1];
          else this.actualpacker = {};
        } else this.data_loading = false;
      }
    },
    cancel() {
      if (!this.btn_click) {
        this.btn_click = true;

        if (this.$refs.form2) this.$refs.form2.resetValidation();
        if (this.$refs.form3) this.$refs.form3.resetValidation();
        this.$nextTick(() => {
          this.size = {};
          this.packer = {};
        });
        this.size_edit = false;
        this.sizes_headers[16].hiden = this.size_edit;
        this.sizes_headers[17].hiden = this.size_edit;
        this.size_key++;
        this.packer_edit = false;
        this.btn_click = false;
      }
    },
    async save_packer() {
      if (!this.btn_click) {
        this.btn_click = true;
        if (this.$refs.form3.validate()) {
          let ok = false;
          this.data_loading = true;
          if (this.packer.id > -1) {
            let v = {
              wellstubularpacker: {
                id: this.packer.id,
                depth: this.packer.depth,
                pressure_rating: this.packer.pressure_rating,
              },
            };
            let r = await this.$maj(UPDATE_TUBULAR_PACKER, v);
            if (r.ok) {
              ok = true;
            } else {
              this.snackbar_text = "Saving Error";
              this.snackbar_color = "error";
              this.snackbar = true;
            }
          } else {
            let v = {
              wellstubularpacker: {
                well_tubular_id: this.editedItem.id,
                depth: this.packer.depth,
                pressure_rating: this.packer.pressure_rating,
              },
            };
            let r = await this.$maj(CREATE_TUBULAR_PACKER, v);
            if (r.ok) {
              this.packer.id = r.data.createWellstubularpacker.id;
              ok = true;
            } else {
              this.snackbar_text = "Saving Error";
              this.snackbar_color = "error";
              this.snackbar = true;
            }
          }
          if (ok) {
            this.$emit("change", this.editedItem);
            this.actualpacker = this.packer;
            this.size_edit = false;
          } else this.data_loading = false;
        }
        this.btn_click = false;
      }
    },
    async save_size() {
      if (!this.btn_click) {
        this.btn_click = true;
        let i = this.apicasing_list.findIndex(
          (elm) =>
            elm.od_in == this.size.od_in &&
            elm.weight_lbm == this.size.weight_lbm &&
            elm.grade == this.size.grade
        );
        if (i >= 0) this.size.api_csc_id = this.apicasing_list[i].id;
        if (this.$refs.form2.validate() && i >= 0) {
          let ok = false;
          this.data_loading = true;
          if (this.size.id > -1) {
            let v = {
              wellstubularsize: {
                id: this.size.id,
                depthfrom: this.size.depthfrom,
                depthto: this.size.depthto,
                api_csc_id: this.size.api_csc_id,
              },
            };
            let r = await this.$maj(UPDATE_TUBULAR_SIZE, v);
            if (r) {
              ok = true;
            }
          } else {
            let v = {
              wellstubularsize: {
                well_tubular_id: this.editedItem.id,
                depthfrom: this.size.depthfrom,
                depthto: this.size.depthto,
                api_csc_id: this.size.api_csc_id,
              },
            };
            let r = await this.$maj(CREATE_TUBULAR_SIZE, v);
            if (r) {
              this.size.id = r.data.createWellstubularsize.id;
              ok = true;
            }
          }
          if (ok) {
            this.$emit("change", this.editedItem);
            this.actualsize = this.size;
            let i = this.apicasing.findIndex(
              (elm) => elm.id == this.size.api_csc_id
            );
            if (i >= 0) {
              this.size.size = this.apicasing[i].size;
              this.size.weight = this.apicasing[i].weight;
              this.size.grade = this.apicasing[i].grade;
            }
          } else this.data_loading = false;
          this.size_edit = false;
          this.sizes_headers[16].hiden = this.size_edit;
          this.sizes_headers[17].hiden = this.size_edit;
          this.size_key++;
        }
        this.btn_click = false;
      }
    },
    async tieback() {
      if (!this.btn_click) {
        this.btn_click = true;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Tie-back this liner !!-Are you sur ?",
            { color: "orange darken-3" }
          )
        ) {
          if (this.$refs.form.validate()) {
            this.editedItem.depthfrom = 0;
            this.editedItem.tubular_id = 2;
            let v = {
              wellstubular: {
                id: this.editedItem.id,
                tubular_id: this.editedItem.tubular_id,
                depthfrom: this.editedItem.depthfrom,
                topcement: this.editedItem.topcement,
                details: this.editedItem.details,
                write_uid: this.$store.state.me.id,
              },
            };
            let r = await this.$maj(UPDATE_TUBULAR, v);
            if (r.ok) {
              this.$emit("change", this.editedItem);
              this.list.splice(this.editedItem.index, 1, this.editedItem);
            }
          }
        }
        this.btn_click = false;
      }
    },
    async save() {
      if (!this.btn_click) {
        this.btn_click = true;
        if (this.$refs.form.validate()) {
          if (this.editedItem.id > -1) {
            let v = {
              wellstubular: {
                id: this.editedItem.id,
                tubular_id: this.editedItem.tubular_id,
                csg_order:
                  this.editedItem.tubular_id == 1
                    ? 1
                    : this.editedItem.csg_order,
                topcement: this.editedItem.topcement,
                sssv: this.editedItem.sssv,
                tvd: this.editedItem.tvd,
                details: this.editedItem.details,
                fluid_id: this.editedItem.fluid_id,
                fluid_density: this.editedItem.fluid_density,
                lot: this.editedItem.lot,
                wh_press_rate: parseFloat(this.editedItem.wh_press_rate),
                maop: parseFloat(this.editedItem.maop),
                alert_min: parseFloat(this.editedItem.alert_min),
                alert_max: parseFloat(this.editedItem.alert_max),
                volume: parseFloat(this.editedItem.volume),
                write_uid: this.$store.state.me.id,
              },
            };
            let r = await this.$maj(UPDATE_TUBULAR, v);
            if (r.ok) {
              this.$emit("change", this.editedItem);
              this.list.splice(this.editedItem.index, 1, this.editedItem);
            } else {
              this.snackbar_text = "Saving Error";
              this.snackbar_color = "error";
              this.snackbar = true;
            }
          } else {
            let v = {
              wellstubular: {
                well_id: this.item.well_id,
                tubular_id: this.editedItem.tubular_id,
                csg_order:
                  this.editedItem.tubular_id == 1
                    ? 1
                    : this.editedItem.csg_order,
                topcement: this.editedItem.topcement,
                sssv: this.editedItem.sssv,
                tvd: this.editedItem.tvd,
                details: this.editedItem.details,
                fluid_id: this.editedItem.fluid_id,
                fluid_density: this.editedItem.fluid_density,
                wh_press_rate: parseFloat(this.editedItem.wh_press_rate),
                maop: parseFloat(this.editedItem.maop),
                alert_min: parseFloat(this.editedItem.alert_min),
                alert_max: parseFloat(this.editedItem.alert_max),
                volume: parseFloat(this.editedItem.volume),
                lot: this.editedItem.lot,
                create_uid: this.$store.state.me.id,
              },
            };
            let r = await this.$maj(CREATE_TUBULAR, v);
            if (r.ok) {
              this.editedItem.id = r.data.createWellsTubular.id;
              this.$emit("add", this.editedItem);
            } else {
              this.snackbar_text = "Saving Error";
              this.snackbar_color = "error";
              this.snackbar = true;
            }
          }
          this.change = 0;
        }
        this.btn_click = false;
      }
    },
    numberWithSpace: function (x) {
      return x
        ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
        : "0";
    },
  },
};
</script>
